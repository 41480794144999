import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/quick-start"
          }}>{`Quick Start`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/quick-start-student"
            }}>{`👩🏽‍🎓 for student`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/quick-start-teacher"
            }}>{`👩🏻‍🏫 for teacher`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/documentation"
          }}>{`Documentation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/how-to-publish"
            }}>{`Publish a tutorial`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/how-to-download"
            }}>{`Download a tutorial`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/contributing"
            }}>{`Contribute`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/customization"
          }}>{`Other`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/customization/"
            }}>{`Customization`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/mdx-components/"
            }}>{`MDX Components`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/navigation/"
            }}>{`Navigation`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`-`}<a parentName="p" {...{
        "href": "/terms-and-conditions"
      }}>{`Terms and Conditions`}</a>{`
- `}{`[Terms and Conditions]`}{` (/terms-and-conditions/)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      